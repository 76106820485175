/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { isBrowser } from "../../services/Utils";

function Seo({
  lang,
  title,
  meta,
  link,
  keywordsContent,
  descriptionContent,
  otherLangsUrls,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
  const [currentLanguage, setCurrentLanguage] = useState('')
  const [langs, setLangs] = useState([
    {
      langCode: "uk",
      default: true
    },
    {
      langCode: "en",
    },
  ]);

  const [interlinksArray, setInterlinksArray] = useState([]);

  useEffect(() => {
    if (isBrowser()) {
      const url = window.location.pathname;
      let currentLang = langs[0].langCode;
      const paths = url.split("/");
      paths.shift();

      if (paths[0].length === 2) {
        currentLang = paths[0];
      }
      setCurrentLanguage(currentLang)

      if (otherLangsUrls) {
        let containsCurrentLanguage = false
        otherLangsUrls.forEach(lang => {
          if (lang.url === generateInterlinkUrl({ langCode: currentLang, default: currentLang === 'uk' })) {
            containsCurrentLanguage = true
          }
        })
        const customInterlinks = [...otherLangsUrls]
        if (containsCurrentLanguage) {
          customInterlinks.push({
            default: true
          })
          createInterlinks(customInterlinks, true)
        } else {
          customInterlinks.push({
            langCode: currentLang,
            url: generateInterlinkUrl({ langCode: currentLang })
          })
          customInterlinks.push({
            default: true
          })
          createInterlinks(customInterlinks, true)
        }
      } else {
        createInterlinks(langs)
      }
    }
  }, []);

  const generateInterlinkUrl = (lang) => {
    let pageOrigin = window.location.origin;
    let interlinkPathname = (window.location.pathname).split("/");
    interlinkPathname.shift()
    if (interlinkPathname[0].length > 2) {
      interlinkPathname.unshift((lang.langCode === 'uk' || lang.default) ? '' : ('/' + lang.langCode));
    } else {
      interlinkPathname.splice(0, 1, ((lang.langCode === 'uk' || lang.default) ? '' : ('/' + lang.langCode)));
    }
    interlinkPathname = interlinkPathname.join("/");

    return pageOrigin + interlinkPathname
  }

  const createInterlinks = (langs, linksPresent) => {
    let arr = [];
    langs.forEach((lang) => {
      const url = generateInterlinkUrl(lang)
      if (lang.default) {
        if (lang.langCode) {
          arr.push({
            rel: "alternate",
            hreflang: lang.langCode,
            href: url
          });
        }
        arr.push({
          rel: "alternate",
          hreflang: 'x-default',
          href: url
        });
      } else {
        arr.push({
          rel: "alternate",
          hreflang: lang.langCode,
          href: (linksPresent && lang.url) ? lang.url : url
        });
      }

    });
    setInterlinksArray(arr);
  }

  const defaultTitle = site.siteMetadata?.title;

  if (!isBrowser()) {
    lang = process.env.PATH_PREFIX || "en";
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: currentLanguage,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s  ${defaultTitle}` : null}
        meta={[
          {
            name: `description`,
            content: descriptionContent,
          },
          {
            name: `keywords`,
            content: keywordsContent,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: descriptionContent,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata?.author || ``,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: descriptionContent,
          },
        ].concat(meta)}
        link={interlinksArray.concat(link)}
      />
    </>
  );
}

Seo.defaultProps = {
  lang: `uk`,
  meta: [],
  link: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  link: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default Seo;
