const { withPrefix } = require("gatsby")
module.exports = {
  changeTitleRout: function (item) {
    const auditSchoolName = item.replace(/[^a-z0-9._]+/gi, "-").toLowerCase()

    return auditSchoolName.slice(-1) === "-"
      ? auditSchoolName.slice(0, -1)
      : auditSchoolName
  },

  isBrowser: function () {
    return typeof window !== "undefined"
  },

  getImageUrl(value) {
    if (!value) {
      return null;
    }
    return  process.env.PROD !== 'false' ? withPrefix('/files/' + value) : `http://localhost:5080/files/${value}`;
  }

}
